export default {
  state: {
    collectionLoanNo: null,
    collectionPortfolioId: null,
    transactionFeeList: [],
    couponDetails: '',
    couponCode: ''
  },
  mutations: {
    setCollectionLoanNo (state, data) {
      state.collectionLoanNo = data
    },
    setCollectionPortfolioId (state, data) {
      state.collectionPortfolioId = data
    },
    setTransactionFeeList (state, data) {
      state.transactionFeeList = data
    },
    setCouponDetails (state, data) {
      state.couponDetails = data
    },
    setCouponCode (state, data) {
      state.couponCode = data
    }
  },
  actions: {
  },
  getters: {
    getCollectionLoanNo: state => state.collectionLoanNo,
    getCollectionPortfolioId: state => state.collectionPortfolioId,
    getTransactionFeeList: state => state.transactionFeeList,
    getCouponDetails: state => state.couponDetails,
    getCouponCode: state => state.couponCode
  }
}
