import swal from 'sweetalert2'
import '@sweetalert2/theme-material-ui/material-ui.scss'

export default {
  install: (Vue) => {
    // efault config
    const mixin = swal.mixin({
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick: false
      // confirmButtonColor: 'rgb(140,212,245)',
      // cancelButtonColor: 'rgb(193,193,193)'
    })

    // Add Global Method
    Vue.swal = mixin
    // Add Install Method
    Vue.prototype.$swal = mixin

    const popWarningAlert = swal.mixin({
      type: 'warning',
      showCancelButton: false,
      allowOutsideClick: false
    })
    const popSuccessAlert = swal.mixin({
      type: 'success',
      text: 'Successful',
      showCancelButton: false,
      allowOutsideClick: false
    })
    const popErrorAlert = swal.mixin({
      type: 'error',
      showCancelButton: false,
      allowOutsideClick: false
    })
    Vue.prototype.$popWarningAlert = popWarningAlert
    Vue.prototype.$popSuccessAlert = popSuccessAlert
    Vue.prototype.$popErrorAlert = popErrorAlert
  }
}
