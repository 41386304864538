import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-config'

const PhoenixConfigApi = {
  getPortfolio (portfolioId, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-portfolio`,
      portfolioId,
      success,
      failure
    )
  },
  getDateParameter (success, failure) {
    return ajax.get(
      `${BASE_URL}/date-format`,
      null,
      success,
      failure
    )
  },
  listPortfolio (success, failure) {
    return ajax.get(
      `${BASE_URL}/list-portfolio`,
      null,
      success,
      failure
    )
  },
  getDomainValue (code, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-domain-value`,
      { code },
      success,
      failure
    )
  },
  getFormByCode (code, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-form`,
      { code },
      success,
      failure
    )
  },
  getConsentTemplate (input, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-consent-template`,
      input,
      success,
      failure
    )
  }
}
export { PhoenixConfigApi }
