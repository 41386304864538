import { DataType } from '@/assets/js/util'

const CustomerBehaviorMap = {
  ajaxMap: {
    // region ACCOUNT_API
    '/infrastructure/batch-save-protocol': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.ACCOUNT_API_ACCEPT_CONSENTS.value
    },
    '/auth/activate-account': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.ACCOUNT_API_CREATE_ACCOUNT_PASSWORD.value
    },
    '/validation/send-code': {
      method: 'post',
      children: [
        {
          route: '/create-account',
          eventSource: DataType.EventSourceEnum.ACCOUNT_API_CREATE_ACCOUNT_ID.value
        }
      ]
    },
    '/validation/verify-code': {
      method: 'post',
      children: [
        {
          route: '/create-account',
          eventSource: DataType.EventSourceEnum.ACCOUNT_API_CREATE_ACCOUNT.value
        }
      ]
    },
    // endregion

    // region ME_API
    '/meta-data/save-identity': {
      method: 'post',
      children: [
        {
          route: '/me',
          eventSource: DataType.EventSourceEnum.ME_API_CONFIRM_PERSONAL_INFORMATION.value
        },
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_PERSONAL_INFORMATION.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_PERSONAL_INFORMATION.value
        }
      ]
    },
    '/meta-data/save-contact': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.ME_API_CONFIRM_CONTACT_INFORMATION.value
    },
    '/meta-data/save-employment': {
      method: 'post',
      children: [
        {
          route: '/me',
          eventSource: DataType.EventSourceEnum.ME_API_CONFIRM_EMPLOYMENT_INFORMATION.value
        },
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_EMPLOYMENT_INFORMATION.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_EMPLOYMENT_INFORMATION.value
        }
      ]
    },
    '/meta-data/save-payroll': {
      method: 'post',
      children: [
        {
          route: '/me',
          eventSource: DataType.EventSourceEnum.ME_API_CONFIRM_PAYROLL_INFORMATION.value
        },
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_PAYROLL_INFORMATION.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_PAYROLL_INFORMATION.value
        }
      ]
    },
    '/meta-data/save-bank-account': {
      method: 'post',
      children: [
        {
          route: '/me',
          eventSource: DataType.EventSourceEnum.ME_API_CONFIRM_BANK_INFORMATION.value
        },
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_BANK_INFORMATION.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CONFIRM_BANK_INFORMATION.value
        }
      ]
    },
    '/auth/change-password': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.ME_API_CHANGE_PASSWORD.value
    },
    // endregion

    // region GUIDANCE_API
    '/ibv/create-request-code': {
      method: 'post',
      children: [
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_DL_CREATE_REQUEST_CODE.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_DL_CREATE_REQUEST_CODE.value
        }
      ]
    },
    '/ibv/get-request-status': {
      method: 'get',
      children: [
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_DL_GET_MULTIPLE_REPORTS.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_DL_GET_MULTIPLE_REPORTS.value
        }
      ]
    },
    '/algorithm/calculate-credit-score': {
      method: 'post',
      children: [
        {
          route: '/guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CALC_LOAN_AMOUNT.value
        },
        {
          route: '/web-guidance',
          eventSource: DataType.EventSourceEnum.GUIDANCE_API_CALC_LOAN_AMOUNT.value
        }
      ]
    },
    // endregion

    // regionDISBURSEMENT API
    '/loan/submit-application': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.PRE_ORIGINATION_API_CREATE_LOAN.value
    },
    '/algorithm/calculate-payment-schedule': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.PRE_ORIGINATION_API_CALCULATE_PAYMENT_SCHEDULE.value
    },
    '/signature/send-loan-agreement': {
      method: 'post',
      eventSource: DataType.EventSourceEnum.PRE_ORIGINATION_API_SIGN_LOAN.value
    }
    // endregion
  }
}
export default CustomerBehaviorMap
