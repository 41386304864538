import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-loan'

const LoanApi = {
  attachLoan (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/attach-loan`,
      input,
      success,
      failure
    )
  },
  listDisbursementLoan (success, failure) {
    return ajax.get(
      `${BASE_URL}/list-disbursement-loan`,
      null,
      success,
      failure
    )
  },
  listRepaymentLoan (success, failure) {
    return ajax.get(
      `${BASE_URL}/list-repayment-loan`,
      null,
      success,
      failure
    )
  }
}

export { LoanApi }
