import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      // light: {
      //   primary: '',
      //   secondary: '',
      //   accent: '',
      //   error: '',
      //   secondaryLight: '',
      //   info: '',
      //   success: '',
      //   warning: ''
      // },
      // dark: {
      //   primary: '',
      //   backgroundDefault: '',
      //   backgroundPrimary: '',
      //   backgroundSecondary: '',
      //   backgroundTertiary: '',
      //   backgroundLeast: '',
      //   foregroundPrimary: '',
      //   foregroundSecondary: '',
      //   foregroundTertiary: '',
      //   foregroundLeast: '',
      //   buttonColor: '',
      //   lightColor: ''
      // }
    }
  }
})
