export default {
  state: {
    dateTimePatterns: {},
    holiday: [],
    portfolioJson: {},
    portfolioId: null
  },
  mutations: {
    setDateTimePatterns (state, data) {
      state.dateTimePatterns = data
    },
    setHoliday (state, data) {
      state.holiday = data
    },
    setPortfolioJson (state, data) {
      state.portfolioJson = data
    },
    setPortfolioId (state, data) {
      state.portfolioId = data
    }
  },
  actions: {
  },
  getters: {
    getDateTimePatterns: state => state.dateTimePatterns,
    getHoliday: state => state.holiday,
    getPortfolioJson: state => state.portfolioJson,
    getPortfolioId: state => state.portfolioId
  }
}
