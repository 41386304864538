export default {
  state: {
    loan: {
      effectiveDate: null,
      loanNo: ''
    },
    repaymentLoanInfo: {},
    approvedAmount: 0,
    preLoanFlag: null, // 从welcome page解析出来，用户以前有过一笔成功的loan，就会有这个flag
    effectiveDateChange: ''
  },
  mutations: {
    setLoan (state, data) {
      state.loan = data
    },
    setRepaymentLoanInfo (state, data) {
      state.repaymentLoanInfo = data
    },
    setApprovedAmount (state, data) {
      state.approvedAmount = data
    },
    setPreLoanFlag (state, data) {
      state.preLoanFlag = data
    },
    setEffectiveDateChange (state, data) {
      state.effectiveDateChange = data
    }
  },
  actions: {
  },
  getters: {
    getLoan: state => state.loan,
    getRepaymentLoanInfo: state => state.repaymentLoanInfo,
    getApprovedAmount: state => state.approvedAmount,
    getEffectiveDateChange: state => state.effectiveDateChange
  }
}
