import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-loan/assistant'

const InfrastructureApi = {
  getCutDownTime (portfolioId, success, failure) {
    return ajax.get(
      BASE_URL + '/countdown-same-day',
      { portfolioId },
      success,
      failure
    )
  },
  getAvailableDate (input, success, failure) {
    return ajax.get(
      BASE_URL + '/available-date',
      input,
      success,
      failure
    )
  },
  getDefaultDisbursementDate (input, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-default-disbursement-date`,
      input,
      success,
      failure
    )
  },
  getHoliday (success, failure) {
    return ajax.get(
      BASE_URL + '/get-holiday',
      null,
      success,
      failure
    )
  },
  getDateTimePatterns (success, failure) {
    return ajax.get(
      BASE_URL + '/date-format',
      null,
      success,
      failure
    )
  },
  getNextBusinessDay (success, failure) {
    return ajax.get(
      BASE_URL + '/get-next-business-day',
      null,
      success,
      failure
    )
  },
  getFutureBusinessDay (success, failure) {
    return ajax.get(
      BASE_URL + '/get-future-date',
      null,
      success,
      failure
    )
  },
  getPortfolio (input, success, failure) {
    return ajax.get(
      BASE_URL + '/portfolio/get',
      input,
      success,
      failure
    )
  },
  mapBankInfo (routingNo, success, failure) {
    return ajax.get(
      BASE_URL + '/get-bank-info',
      { rn: routingNo },
      success,
      failure
    )
  },
  getPortfolioByDomain (domain, success, failure) {
    return ajax.get(
      BASE_URL + '/get-portfolio-by-domain',
      { domain },
      success,
      failure
    )
  }
}
export { InfrastructureApi }
