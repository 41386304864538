import { CustomerInfrastructureApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'
import store from '../store'

const tool = new Tool()
const CommonRequest = {
  addCustomerBehaviorRecords (path, eventType, eventSource, openId) {
    const request = {
      userAgent: navigator.userAgent,
      systemCode: DataType.SystemCodeEnum.PHOENIX.value,
      sessionId: store.getters.getSessionId,
      pageUrl: path,
      eventType: eventType,
      eventSource: eventSource,
      eventSourceText: tool.getEnumTextByValue(DataType.EventSourceEnum, eventSource),
      openId: openId,
      userDevice: tool.isPC() ? DataType.UserDeviceEnum.WEB.value : DataType.UserDeviceEnum.WAP.value,
      systemVersion: '1.0.0',
      lv1: getLevel(eventSource, 1),
      lv2: getLevel(eventSource, 2),
      lv3: getLevel(eventSource, 3)
    }
    CustomerInfrastructureApi.addCustomerBehaviorRecords(request)
  }
}
function getLevel (eventSource, level) {
  switch (level) {
    case 1:
      return tool.getEnumTextByValue(DataType.Level1, eventSource.toString().substring(0, 2))
    case 2:
      return tool.getEnumTextByValue(DataType.Level2, eventSource.toString().substring(2, 4))
    case 3:
      return tool.getEnumTextByValue(DataType.Level3, eventSource.toString().substring(4, 6))
    default:
      break
  }
}

export { CommonRequest }
