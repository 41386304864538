import Vue from 'vue'

import store from './assets/js/store/index'
import router from './settings/router/'
import vuetify from './plugins/vuetify'

import '@/plugins/perfect-scrollbar'
import '@/plugins/workarea-layout'

import './plugins/register-service-worker'
import './plugins/storage'
import './plugins/vee-validate'
import '@/assets/styles/fakeLoader.css'
import '@/assets/styles/components-icon.css'
import '@/assets/js/util/prototype'

import '@/assets/styles/framesets/main.sass'

import App from './App.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SForm from '@/components/widget/SForm'
import SDialog from '@/components/widget/SDialog'
import SButton from '@/components/widget/SButton'

// Plugin simple-uploader
import uploader from 'vue-simple-uploader'
import sweetalert2 from './plugins/sweetalert2'
// 下划线
import underscore from 'vue-underscore'
import VueTheMask from 'vue-the-mask'
import 'animate.css'

Vue.use(uploader)
Vue.use(sweetalert2)
Vue.use(underscore)
Vue.use(VueTheMask)

Vue.component(VuePerfectScrollbar.name, VuePerfectScrollbar)
Vue.component(SForm.name, SForm)
Vue.component(SDialog.name, SDialog)
Vue.component(SButton.name, SButton)

Vue.config.productionTip = false

Vue.prototype.store = store

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
})

app.$mount('#app')

const isDebugMode = process.env.VUE_APP_TITLE !== 'production'
Vue.config.devtools = isDebugMode
Vue.config.productionTip = isDebugMode
