import { Tool } from '@/assets/js/util'
const tool = new Tool()

let isReady = false
let socket = null
let heart = null
let timeInt = null
let time = 0
const defaults = {
  url: 'localhost',
  mode: 'ClientSide',
  wsid: 'CREATE',
  ping: { ticket: 'PING' }
}

/**
 * Create A Websocket Object
 */
export function Sloth (options) {
  this.initOptions(options)
}

/**
 * Extend the prototype
 * @type {{constructor: Sloth, initOptions: Sloth.initOptions}}
 */
Sloth.prototype = {
  constructor: this,
  initOptions: function (options) {
    this.defaults = extend(defaults, options, true)
    defaults.url = defaults.url.replace('http://', '')
    this.eventListeners = []
    this.eventHandlers = {}
  },
  init: function () {
    initWebsocket(this)
    return this
  },
  on: function (type, handler) {
    if (typeof this.eventHandlers[type] === 'undefined') {
      this.eventHandlers[type] = []
    }
    this.eventListeners.push(type)
    this.eventHandlers[type].push(handler)
    return this
  },
  off: function (type, handler) {
    if (this.eventHandlers[type] instanceof Array) {
      const eventHandlers = this.eventHandlers[type]
      const length = eventHandlers.length
      let i = 0
      for (i; i < length; ++i) {
        if (eventHandlers[i] === handler) {
          break
        }
      }
      this.eventListeners.splice(i, 1)
      eventHandlers.splice(i, 1)
      return this
    }
  },
  status: function () {
    return socket.readyState
  },
  sendMessage: function (message) {
    if (isReady && socket != null) {
      socket.send(message)
    }
  },
  close: function () {
    socket.close()
    clearInterval(heart)
    heart = null
  },
  triggerEvent: function (type, data) {
    if (this.eventHandlers[type] instanceof Array) {
      const eventHandlers = this.eventHandlers[type]
      for (let i = 0, length = eventHandlers.length; i < length; ++i) {
        eventHandlers[i]({
          type: type,
          target: this,
          data: data
        })
      }
      return true
    }
    return false
  }
}

/**
 * Init websocket and Build Connection
 */
function initWebsocket (wsxch) {
  if ('WebSocket' in window) {
    const protocol = 'wss'
    socket = new WebSocket('{0}://{1}/channel/{2}/{3}'.format(
      protocol,
      wsxch.defaults.url,
      tool.getSystemCode(),
      wsxch.defaults.wsid
    ))
    socket.onopen = function () {
      isReady = true
      wsxch.triggerEvent('open')
    }
    socket.onerror = function () {
      wsxch.triggerEvent('error')
    }
    socket.onclose = function (e) {
      wsxch.triggerEvent('close')
      clearInterval(heart)
      clearInterval(timeInt)
      heart = null
      timeInt = null
    }
    socket.onmessage = function (event) {
      const data = JSON.parse(event.data)
      if (data.heartbeat) {
        time = 0
      } else {
        wsxch.triggerEvent('message', data)
      }
    }
    window.onbeforeunload = function () {
      socket.close()
      clearInterval(heart)
      clearInterval(timeInt)
      heart = null
      timeInt = null
    }
    heartbeat(wsxch)
  }
}

function heartbeat (wsxch) {
  time = 0
  defaults.ping.destination = defaults.wsid
  heart = setInterval(function () {
    socket.send(JSON.stringify({
      destination: wsxch.defaults.wsid,
      messageType: 1,
      messageData: {
        heartbeat: true
      }
    }))
  }, 30 * 1000)
  timeInt = setInterval(function () {
    if (time > 60) {
      clearInterval(heart)
      clearInterval(timeInt)
      heart = null
      timeInt = null
      socket.close()
      initWebsocket(wsxch)
    }
    time++
  }, 1000)
}

function extend (target, data, override) {
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && (!target.hasOwnProperty(key) || override)) {
      target[key] = data[key]
    }
  }
  return target
}
