import Vue from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'

import 'perfect-scrollbar/css/perfect-scrollbar.css'

const scrollbar = (element) => {
  if (element.__perfect_scrollbar instanceof PerfectScrollbar) {
    element.__perfect_scrollbar.update()
  } else {
    element.__perfect_scrollbar = new PerfectScrollbar(element, {
      // check details on https://github.com/mdbootstrap/perfect-scrollbar#options
    })
  }
}

Vue.directive('scrollbar', {
  inserted (element, binding, node) {
    // const rules = ['fixed', 'absolute', 'relative', 'sticky']
    //
    // if (!rules.includes(window.getComputedStyle(element, null).position)) {
    //   console.error('the position of container that hosted perfect-scrollbar should have specified value')
    // }

    scrollbar(element)
  },
  componentUpdated (element, binding, node, oldNode) {
    node.context.$nextTick(() => {
      scrollbar(element)
    })
  }
})
