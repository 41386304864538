export default {
  state: {
    documentUrl: '',
    resource: '',
    isConsent: null
  },

  getters: {
    getDocumentUrl: state => state.documentUrl,
    getResource: state => state.resource,
    getIsConsent: state => state.isConsent
  },

  mutations: {
    setDocumentUrl (state, data) {
      state.documentUrl = data
    },
    setResource (state, data) {
      state.resource = data
    },
    setIsConsent (state, data) {
      state.isConsent = data
    }
  }
}
