const state = {
  popupInformation: {
    type: null,
    message: null,
    color: ''
  },
  webSocket: {
    ticket: null,
    data: null
  }
}

const getters = {
  getPopupInformation: state => state.popupInformation,
  getWebSocket: state => state.webSocket
}

const actions = {}

const mutations = {
  setPopupInformation (state, popupInformation) {
    state.popupInformation = popupInformation
  },
  resetPopupInformation (state, data) {
    state.popupInformation = {
      type: null,
      message: null,
      color: ''
    }
  },
  setWebSocket (state, webSocket) {
    state.webSocket = webSocket
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
