import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/gateway/servicebus-fssapi/fssapi/infrastructure'

const CustomerInfrastructureApi = {
  getPortfolioPrograms (success, failure) {
    return ajax.get(
      `${BASE_URL}/get-portfolio-program`,
      null,
      success,
      failure
    )
  },
  addCustomerBehaviorRecords (input, success, failure) {
    return ajax.post(
      BASE_URL + '/save-behavior-record',
      input,
      success,
      failure
    )
  },
  parsePathResource (input, success, failure) {
    return ajax.post(
      BASE_URL + '/parse-path-resource',
      input,
      success,
      failure
    )
  }
}

export { CustomerInfrastructureApi }
