<template>
  <v-dialog
    v-model="display"
    persistent
    :max-width=maxWidth
  >
    <v-card>
      <v-toolbar
        elevation="0"
        color="transparent"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="form-heading-actions"></slot>
        <v-btn
          icon
          @click="onClickCloseDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <slot name="dialog-content"></slot>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <slot name="dialog-actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SDialog',
  data: () => ({
    display: false
  }),
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    maxWidth: {
      type: String,
      default: '600px'
    }
  },
  watch: {
    value (val) {
      this.display = val
    }
  },
  methods: {
    onClickCloseDialog: function () {
      this.display = false
      this.$emit('input', this.display)
    }
  }
}
</script>

<style lang="sass" scoped>
  .v-dialog > .v-card > .v-card__actions
    margin: 0
    padding: 0 24px 10px
</style>

<style lang="sass">
  .dialog-actions .v-btn
    width: 100px
</style>
