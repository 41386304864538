<template>
  <div
    v-if="tooltip === true"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :small="small"
          :icon="icon"
          :text="text"
          :color="color"
          v-bind="attrs"
          v-on="on"
          :loading="loading"
          :disabled="disabled"
          @click="onClick"
        >
          <slot></slot>
        </v-btn>
      </template>
      <span>{{ tooltipData }}</span>
    </v-tooltip>
  </div>
  <div
    v-else
  >
    <v-btn
      :icon="icon"
      :text="text"
      :loading="loading"
      @click="onClick"
    >
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SButton',
  props: {
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipData: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick: function (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="sass" scoped></style>
