import Vue from 'vue'
import Vuex from 'vuex'
// region import store
import portfolio from './modules/portfolio'
import loan from './modules/loan'
import workflow from './modules/workflow'
import notification from './modules/notification'
import application from './modules/application'
import coupon from './modules/coupon'
import onlineSignature from './modules/online-signature'
// endregion
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
// encryptionSecret:自定义密钥
const ls = new SecureLS({ isCompression: false, encryptionSecret: '38c31684-d00d-30dc-82e0-fad9eec46d1d' })
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    portfolio,
    loan,
    workflow,
    notification,
    application,
    coupon,
    onlineSignature
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    },
    // 需要持久化的模块
    paths: ['loan', 'workflow', 'coupon', 'onlineSignature', 'portfolio']
  })]
})

export default store
