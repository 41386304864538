import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-loan/online/payment/multi-installment'

const PaymentApi = {
  payOffInN (input, success, failure) {
    return ajax.post(
      BASE_URL + '/pay-off-in-n',
      input,
      success,
      failure
    )
  },
  payFixedAmount (input, success, failure) {
    return ajax.post(
      BASE_URL + '/pay-fixed-amount',
      input,
      success,
      failure
    )
  },
  oneTimePayment (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/pay-off`,
      input,
      success,
      failure
    )
  }
}
export { PaymentApi }
