import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/assets/js/store/index'
import { WebConfig } from '@/settings/router/web-config'
import { Cache } from '@/assets/js/util'

Vue.use(VueRouter)
Vue.use(store)

const cache = new Cache()
const router = new VueRouter()

WebConfig.forEach(item => {
  router.addRoute(item)
})

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  // const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    window.location.reload()
  }
})

/* 路由拦截 */
router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/simulator') !== -1 && process.env.VUE_APP_TITLE === 'production') {
    next('/')
  }
  if (to.matched.length === 0) {
    next('/404')
  } else {
    to.path === '/sign-in' && cache.removeAccessToken()
    next()
  }
})
// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
export default router
