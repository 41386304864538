<template>
  <div>
    <v-form v-if="assists.tool.isNotEmpty(settings.elements)" ref="form" v-bind="settings.originalProperty">
      <ValidationObserver ref="observer" v-slot="{ validate, reset }">
        <v-row v-bind="settings.originalProperty">
          <template v-for="(field, index) in settings.elements">
            <v-col :cols="field.foregroundProperty.cols" :key="index">
              <ValidationProvider
                :name="field.elementLabel"
                :rules="field.foregroundProperty.validateRules"
                v-slot="{ errors }"
                style="width: 100%"
              >
                <v-radio-group
                  v-if="field.dataControl === 'VRadioGroup'"
                  v-bind="field.controlProperty"
                  v-model="current[field.elementKey]"
                  :label="field.elementLabel"
                  :error-messages="errors[0]"
                  hide-details="auto"
                >
                  <v-radio
                    v-for="(option, index) in field.dataDomainValues"
                    :key="index"
                    :label="option.text"
                    :value="option.value"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                  </v-radio>
                </v-radio-group>
                <template v-else-if="field.dataControl === 'VDatePicker'">
                  <v-menu
                    v-model="menu"
                    v-bind="field.controlProperty"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-bind="field.controlProperty"
                        readonly
                        slot="activator"
                        :label="field.elementLabel"
                        :value="
                          assists.tool.isNotEmpty(current[field.elementKey])
                            ? assists.tool.formatDateStrByPattern(current[field.elementKey], 'MMM dd, yyyy')
                            : ''
                        "
                        append-outer-icon="event"
                        v-on="on"
                        hide-details="auto"
                      >
                        <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :value="assists.tool.formatDateStrByPattern(current[field.elementKey], 'YYYY-MM-DD')"
                      @change="(val) => current[field.elementKey] = assists.tool.formatDateStrByPattern(val, $store.getters.getDateTimePatterns.date)"
                      v-bind="field.controlProperty"
                      scrollable
                      color="var(--theme_primary)"
                      :allowed-dates="(val) => getAllowedDates(val, field.foregroundProperty)"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <template v-else-if="field.dataControl === 'VTextField'">
                  <v-text-field
                    v-if="assists.tool.isNotEmpty(field.foregroundProperty.format)"
                    v-bind="field.controlProperty"
                    :value="
                      field.foregroundProperty.format === 'currency'
                        ? assists.tool.formatCurrency(current[field.elementKey])
                        : current[field.elementKey]
                    "
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    @blur="$emit('blurAction', field.elementKey, current)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else-if="
                      assists.tool.isNotEmpty(field.foregroundProperty) &&
                      assists.tool.isNotEmpty(field.foregroundProperty.mask)
                    "
                    v-bind="field.controlProperty"
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    v-mask="field.foregroundProperty.mask"
                    @blur="$emit('blurAction', field.elementKey, current)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-bind="field.controlProperty"
                    v-model="current[field.elementKey]"
                    :label="field.elementLabel"
                    :error-messages="errors[0]"
                    hide-details="auto"
                    @blur="$emit('blurAction', field.elementKey, current)"
                  >
                    <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                  </v-text-field>
                </template>

                <template v-else-if="field.dataControl === 'VText'">
                  <span
                    v-if="assists.tool.isNotEmpty(field.controlProperty.text)"
                    v-html="formatQuestion(field.controlProperty.text)"
                    :class="field.foregroundProperty.labelRowClass"
                  ></span>
                  <span v-else>
                    <v-row dense>
                      <v-col
                        v-if="field.foregroundProperty.hideLabel === 'true'"
                        :class="field.foregroundProperty.colClass"
                      >
                        {{ assists.tool.sectionRender(field, current, currentLockedId) }}
                      </v-col>
                      <template v-else-if="field.foregroundProperty.labelPosition === 'front'">
                        <v-col
                          :cols="field.foregroundProperty.labelCols"
                          :class="field.foregroundProperty.labelRowClass"
                        >
                          {{ field.elementLabel }}
                        </v-col>
                        <v-col :class="field.foregroundProperty.colClass">
                          {{ assists.tool.sectionRender(field, current, currentLockedId) }}
                        </v-col>
                      </template>
                    </v-row>
                  </span>
                </template>

                <template v-else-if="field.dataControl === 'VDivider'">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </template>

                <template v-else-if="field.dataControl === 'VSpacer'">
                  <v-spacer></v-spacer>
                </template>
                <component
                  v-else
                  v-bind="field.controlProperty"
                  :is="field.dataControl"
                  v-model="current[field.elementKey]"
                  :label="field.elementLabel"
                  :items="field.dataDomainValues"
                  :error-messages="errors[0]"
                  hide-details="auto"
                >
                  <template #label>
                      <span>{{ field.elementLabel }}</span>
                      <span v-if="field.foregroundProperty.mandatory === 'true'" class="red-dot"> *</span>
                    </template>
                </component>
              </ValidationProvider>
            </v-col>
          </template>
        </v-row>
      </ValidationObserver>
    </v-form>
    <!-- <SSkeleton v-else :data-template-code="dataTemplateCode"></SSkeleton> -->
  </div>
</template>

<script>
import { PhoenixConfigApi } from '@/api'
import { VTextField, VSelect, VSwitch } from 'vuetify/lib'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Tool, Popup } from '@/assets/js/util'
import { ajax } from '@/assets/js/util/ajax'
import { _ } from 'vue-underscore'
// import SSkeleton from '@/components/widget/SSkeleton'

export default {
  name: 'SForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    VTextField,
    VSelect,
    VSwitch
    // SSkeleton
  },
  props: {
    value: {
      type: [Object, Array]
    },
    dataTemplateCode: {
      type: String,
      required: true
    },
    actionParameters: {
      type: Object
    },
    saveParameters: {
      type: Object
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    formSettingFromSection: {
      type: Object
    }
  },
  data () {
    return {
      menu: false,
      assists: {
        tool: new Tool(),
        popup: new Popup()
      },
      current: {},
      original: {},
      formsSettings: {},
      currentLockedId: null,
      holiday: this.store.getters.getHoliday,
      dateFormat: this.store.getters.getDateFormat
    }
  },
  watch: {
    '$store.getters.getCurrentLockedId': {
      handler (value) {
        // 重新赋值，触发视图更新
        this.currentLockedId = value
      },
      immediate: true
    },
    value (val) {
      if (this.assists.tool.isNotEmpty(val)) {
        this.current = { ...val }
      }
    },
    formSettingFromSection: {
      handler (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.formsSettings = val
        }
      }
    },
    actionParameters: {
      handler (val) {
        if (this.assists.tool.isNotEmpty(val) && this.assists.tool.isNotEmpty(this.formsSettings)) {
          this.load()
        }
      },
      deep: true
    }
  },
  computed: {
    settings () {
      const initForm = JSON.parse(JSON.stringify(this.formsSettings))
      let formSettings = initForm[this.dataTemplateCode]

      if (this.assists.tool.isEmpty(formSettings)) {
        formSettings = {
          originalProperty: {},
          elements: []
        }
      }
      if (this.assists.tool.isNotEmpty(formSettings.associates)) {
        const elements = formSettings.elements

        elements.map((item, index) => {
          formSettings.associates.forEach((val) => {
            if (
              this.assists.tool.isNotEmpty(val) &&
              this.judgmentDisplayAssociate(val) &&
              item.templateElementId === val.sortId
            ) {
              const childrenForm = initForm[val.code]
              elements.splice(index + 1, 0, ...childrenForm.elements)
            }
          })
        })
      }

      formSettings.elements.map((item) => {
        if (this.assists.tool.isNotEmpty(item.controlProperty)) {
          this.transferStringValue(item.controlProperty)
        }
      })

      if (this.assists.tool.isNotEmpty(formSettings.originalProperty)) {
        this.transferStringValue(formSettings.originalProperty)
      }

      return formSettings
    }
  },
  mounted () {
    if (this.assists.tool.isEmpty(this.formSettingFromSection)) {
      this.getFormByCode()
    } else {
      this.formsSettings = this.formSettingFromSection
      this.$emit('syncTemplate')
    }
  },
  methods: {
    getFormByCode () {
      PhoenixConfigApi.getFormByCode(this.dataTemplateCode, (res) => {
        this.formsSettings = res

        // 将read url传给父组件
        const formSettings = res[this.dataTemplateCode]

        // 如是携带数据，则不请求接口
        if (this.assists.tool.isNotEmpty(this.value)) {
          this.current = this.value
          return
        }

        if (this.assists.tool.isNotEmpty(formSettings?.actionProperty)) {
          this.$emit('syncTemplate')
        }
      })
    },
    load () {
      let url = this.formsSettings[this.dataTemplateCode].actionProperty.read.url

      if (this.assists.tool.isNotEmpty(this.actionParameters)) {
        url = url.replace(/\{([^}]+)\}/g, (_, value) => this.actionParameters[value])
      }

      ajax.get(url, {}, (res) => {
        this.current = { ...res }
        this.original = { ...res }
        this.$emit('syncData', { ...res })
      })
    },
    saveForm () {
      const formSettings = this.formsSettings[this.dataTemplateCode]
      let url = ''

      this.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          const currentData = this.unmaskData(formSettings.elements, this.current)
          const originalData = this.unmaskData(formSettings.elements, this.original)
          if (this.assists.tool.deepCompare(currentData, originalData)) {
            return this.$emit('saveCallBack', currentData)
          }
          const finalData = this.compareDataObjects(currentData, originalData)

          // 判断update or create
          if (this.assists.tool.isNotEmpty(this.saveParameters.payload[this.primaryKey])) {
            url = formSettings.actionProperty.update.url
          } else {
            url = formSettings.actionProperty.create.url
          }

          this.$emit('loadingCallBack', true)
          ajax.post(
            url,
            {
              [this.saveParameters.externalKey]: finalData,
              ...this.saveParameters.payload
            },
            (res) => {
              this.$emit('saveCallBack', currentData)
              this.$emit('loadingCallBack', false)
            },
            (err) => {
              this.$emit('loadingCallBack', false)
              this.$store.commit('setPopupInformation', {
                message: err.message
              })
            }
          )
        }
      })
    },
    compareDataObjects (current, original) {
      // 对比修改前后的数组，只把已修改的数据传给后端
      const finalObj = {}

      for (const key in current) {
        if (!Object.prototype.hasOwnProperty.call(original, key) || current[key] !== original[key]) {
          finalObj[key] = current[key]
        }
      }

      return finalObj
    },
    unmaskData (elements, val) {
      // const data = { ...val }
      const data = JSON.parse(JSON.stringify(val))

      for (const element of elements) {
        const newData = data[element.elementKey]
        if (this.assists.tool.isEmpty(newData)) {
          continue
        }

        const foregroundProperty = element.foregroundProperty
        if (foregroundProperty && foregroundProperty.mask) {
          data[element.elementKey] = newData.replace(/[^0-9]/gi, '')
        }
      }

      return data
    },
    formatQuestion (question) {
      return _.template(question)({
        data: {
          fullName: this.assists.tool.getFullName(this.$store.getters.getPersonalSection)
        }
      })
    },
    transferStringValue (property) {
      for (const key of Object.keys(property)) {
        if (property[key] === 'true') {
          property[key] = true
        } else if (property[key] === 'false') {
          property[key] = false
        }
      }
    },
    judgmentDisplayAssociate (associate) {
      return associate.items.every((items) => {
        return this.current[items.key] === items.value
      })
    },
    getAllowedDates (val, property) {
      let result = true

      if (property && property.allowedDates) {
        const allowedDates = property.allowedDates
        if (allowedDates.includes('end-now')) {
          result = result && val <= this.assists.tool.formatDate(new Date().getTime())
        }
        if (allowedDates.includes('start-now')) {
          result = result && val >= this.assists.tool.formatDate(new Date().getTime())
        }
        if (allowedDates.includes('no-weekend')) {
          const time = new Date(val)
          result = result && time.getUTCDay() !== 0 && time.getUTCDay() !== 6
        }
        if (allowedDates.includes('no-holiday')) {
          result = result && !this.holiday.find((item) => item === val)
        }
      }

      return result
    }
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #ff5050
</style>
