<template>
  <div>
    <v-snackbar
      v-model="layouts.data.showPopup"
      :color="layouts.data.popupInformation.color"
      :timeout="2000"
      :top=true
    >
      {{ layouts.data.popupInformation.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="onClickBtn"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'popup-information',
  data () {
    return {
      layouts: {
        data: {
          popupInformation: {
            showAction: false,
            type: null,
            message: null,
            color: ''
          },
          showPopup: false
        }
      }
    }
  },
  watch: {
    'store.getters.getPopupInformation': {
      handler (val) {
        if (tool.isNotEmpty(val) && tool.isNotEmpty(val.message)) {
          if (tool.isNotEmpty(val.type)) {
            switch (val.type) {
              case 'success':
                val.color = '#353535'
                break
              case 'error':
                val.color = '#F44336'
            }
          } else if (tool.isNotEmpty(val.color)) {

          } else {
            val.color = '#353535'
          }
          this.layouts.data.popupInformation = val
          this.layouts.data.showPopup = true
          this.$store.commit('resetPopupInformation')
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
  },
  methods: {
    onClickBtn () {
      this.layouts.data.showPopup = false
    }
  }
}
</script>

<style scoped>

</style>
