/* Prototype */
// eslint-disable-next-line no-extend-native
String.prototype.format = function (args) {
  let result = this

  if (arguments.length > 0) {
    if (arguments.length === 1 && typeof (args) === 'object') {
      for (const key in args) {
        if (args[key] !== undefined) {
          const reg = new RegExp('({' + key + '})', 'g')
          result = result.replace(reg, args[key])
        }
      }
    } else {
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] !== undefined) {
          const reg = new RegExp('({)' + i + '(})', 'g')
          result = result.replace(reg, arguments[i])
        }
      }
    }

    if (result.hasPlaceholder()) {
      result = result.clearPlaceholder()
    }
  }

  return result
}

// eslint-disable-next-line no-extend-native
String.prototype.hasPlaceholder = function () {
  return /{(.+?)}/g.test(this)
}

// eslint-disable-next-line no-extend-native
String.prototype.fetchPlaceholder = function () {
  return this.match(/{(.+?)}/g)
}

// eslint-disable-next-line no-extend-native
String.prototype.clearPlaceholder = function () {
  let source = this

  const items = source.fetchPlaceholder()
  for (const index in items) {
    if (index) {
      const item = items[index]

      source = source.replace(item, '')
    }
  }

  return source
}

// eslint-disable-next-line no-extend-native
String.prototype.toJson = function () {
  const source = this

  if (source.isJson()) {
    return JSON.parse(source)
  } else {
    return source
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.isJson = function () {
  let source = this

  // Step-1: Repair to @
  // eslint-disable-next-line no-useless-escape
  source = source.replace(/\\["\\\/bfnrtu]/g, '@')
  // Step-2: Repair to ]
  // eslint-disable-next-line no-useless-escape
  source = source.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
  // Step-3: Repair to White-Space
  source = source.replace(/(?:^|:|,)(?:\s*\[)+/g, '')

  return /^[\],:{}\s]*$/.test(source)
}

/*******************************************************************************
 * 日期扩展类
 *
 * 1.0.0 WolfLai
 ******************************************************************************/
/**
 * 日期类型转化为指定格式字符串
 *
 * @param {Object} pattern
 *        月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2个占位符
 *        年(y)可以用 1-4 个占位符
 *        毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 *        自定义格式：
 *            (new Date()).format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *            (new Date()).format("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 *            (new Date()).format("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 *            (new Date()).format("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 *            (new Date()).format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 *        内置格式：
 *            PATTERN_SHORT_DATE = "yyyy-M-d"
 *            PATTERN_SHORT_TIME = "H:m:s"
 *            PATTERN_LONG_DATE = "yyyy-MM-dd"
 *            PATTERN_LONG_TIME = "HH:mm:ss"
 *            PATTERN_DATETIME = "yyyy-MM-dd HH:mm:ss"
 */
// eslint-disable-next-line no-extend-native
Date.prototype.format = function (pattern) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours() % 12 === 0 ? 12 : this.getHours() % 12, // 小时
    'H+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }

  const week = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }

  if (/(y+)/.test(pattern)) {
    pattern = pattern.replace(RegExp.$1, (String(this.getFullYear())).substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(pattern)) {
    pattern = pattern.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? 'Weekend' : 'Week') : '') + week[String(this.getDay())])
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(pattern)) {
      pattern = pattern.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr((String(o[k])).length)))
    }
  }
  return pattern
}

// eslint-disable-next-line no-extend-native
Array.prototype.union = function (target) {
  let source = this

  source = source.concat(target.filter(
    item => !source.includes(item)
  ))

  return source
}

// eslint-disable-next-line no-extend-native
Array.prototype.intersection = function (target) {
  let source = this

  source = source.filter(
    item => target.includes(item)
  )

  return source
}

// eslint-disable-next-line no-extend-native
Array.prototype.difference = function (target) {
  let source = this

  source = source.concat(target).filter(
    item => source.includes(item) && !target.include(item)
  )

  return source
}
