import swal from 'sweetalert2'
import '@sweetalert2/theme-material-ui/material-ui.scss'
import { Tool } from '@/assets/js/util/index'

const tool = new Tool()
class Popup {
  popupForSuccess (message, callback) {
    if (tool.isEmpty(message)) {
      message = 'Successful'
    }
    swal({
      type: 'success',
      text: message,
      showCancelButton: false,
      allowOutsideClick: false
    }).then(result => {
      result = result.value
      if (tool.isNotEmpty(result) && result && tool.isNotEmpty(callback)) {
        callback()
      }
    })
  }

  popupForError (message, callback) {
    if (tool.isEmpty(message)) {
      message = 'Error'
    }
    swal({
      type: 'error',
      text: message,
      showCancelButton: false,
      allowOutsideClick: false
    }).then(result => {
      result = result.value
      if (tool.isNotEmpty(result) && result && tool.isNotEmpty(callback)) {
        callback()
      }
    })
  }

  popupForInfo (message, option, callbackTrue, callbackFalse) {
    if (tool.isEmpty(message)) {
      message = ''
    }

    const options = {
      type: 'info',
      text: message,
      showCancelButton: false,
      allowOutsideClick: false
    }

    this.commonPopup(options, option, callbackTrue, callbackFalse)
  }

  popupForWarning (message, option, callbackTrue, callbackFalse) {
    if (tool.isEmpty(message)) {
      message = 'Warning'
    }

    const options = {
      type: 'warning',
      text: message,
      showCancelButton: false,
      allowOutsideClick: false
    }

    this.commonPopup(options, option, callbackTrue, callbackFalse)
  }

  popupForQuestion (message, option, callbackTrue, callbackFalse) {
    if (tool.isEmpty(message)) {
      message = ''
    }

    const options = {
      type: 'question',
      text: message,
      showCancelButton: true,
      allowOutsideClick: false
    }

    this.commonPopup(options, option, callbackTrue, callbackFalse)
  }

  commonPopup (options, option, callbackTrue, callbackFalse) {
    if (tool.isNotEmpty(option)) {
      Object.assign(options, option)
    }

    swal(options).then(result => {
      result = result.value
      if (tool.isNotEmpty(result) && result) {
        if (tool.isNotEmpty(callbackTrue)) {
          callbackTrue()
        }
      } else {
        if (tool.isNotEmpty(callbackFalse)) {
          callbackFalse()
        }
      }
    })
  }
}

export { Popup }
