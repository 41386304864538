import { render, staticRenderFns } from "./SDialog.vue?vue&type=template&id=70333342&scoped=true"
import script from "./SDialog.vue?vue&type=script&lang=js"
export * from "./SDialog.vue?vue&type=script&lang=js"
import style0 from "./SDialog.vue?vue&type=style&index=0&id=70333342&prod&lang=sass&scoped=true"
import style1 from "./SDialog.vue?vue&type=style&index=1&id=70333342&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70333342",
  null
  
)

export default component.exports