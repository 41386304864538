import { Tool } from '@/assets/js/util/index'
const moment = require('moment')

const ATTRIBUTE_ACCESS_TOKEN = 'Access-Token'
const ATTRIBUTE_USER_CREDENTIAL = 'User-Credential'
class Cache {
  setUserCredential (token) {
    window.localStorage.setItem(ATTRIBUTE_USER_CREDENTIAL, token)
  }

  getUserCredential () {
    return window.localStorage.getItem(ATTRIBUTE_USER_CREDENTIAL)
  }

  setAccessToken (token) {
    window.localStorage.setItem(ATTRIBUTE_ACCESS_TOKEN, token)
  }

  getAccessToken () {
    return window.localStorage.getItem(ATTRIBUTE_ACCESS_TOKEN)
  }

  removeAccessToken () {
    window.localStorage.removeItem(ATTRIBUTE_ACCESS_TOKEN)
  }

  // clear cached sign-in data after sign-out
  clearSignInData () {
    localStorage.removeItem(ATTRIBUTE_ACCESS_TOKEN)
  }

  setItem (key, value) {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
    }
  }

  getItem (key) {
    let itemValue = localStorage.getItem(key)

    const tool = new Tool()

    if (tool.isNotEmpty(itemValue)) {
      if (itemValue.isJson()) {
        itemValue = itemValue.toJson()
      }
    }

    return itemValue
  }

  removeItem (key) {
    localStorage.removeItem(key)
  }

  clear () {
    localStorage.clear()
  }

  setCookie (name, value, expire) {
    const exDay = moment().add(expire, 'ms').toString()
    document.cookie = name + '=' + escape(value) + ((expire == null) ? '' : ';expires=' + exDay)
  }

  getCookie (name) {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(name + '=')
      if (start !== -1) {
        start = start + name.length + 1
        let end = document.cookie.indexOf(';', start)
        if (end === -1) end = document.cookie.length
        return unescape(document.cookie.substring(start, end))
      }
    }
    return ''
  }
}

export { Cache }
